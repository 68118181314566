import React from 'react'

import LeadershipContent from 'components/Leadership'
import SEO from 'components/SEO'

const Leadership = () => {
  return (
    <>
      <SEO title="Leadership" />
      <LeadershipContent />
    </>
  )
}

export default Leadership
