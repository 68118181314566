import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Advisory from './Advisory'
import { usePageIconsImages } from 'hooks/usePageIconsImagesHook'
import Intro from 'elements/Intro'

const LeadershipContent = () => {
  const {
    rawData: { edges: leadership }
  } = useStaticQuery(query)

  const {
    leadershipIcon: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src }
          }
        }
      }
    }
  } = usePageIconsImages()
  return (
    <section className="py-16">
      <div className="container mx-auto px-8">
        <Intro pageTitle="Leadership" imgSrc={src} />
        <div>
          <h2 className="text-4xl mb-12 font-bold mt-14">Leadership board</h2>
          {leadership.length &&
            leadership.map(
              ({
                node: {
                  id,
                  name,
                  role,
                  description,
                  imageSrc: {
                    childImageSharp: { gatsbyImageData }
                  }
                }
              }) => {
                return (
                  <div className="flex flex-col mb-12 max-w-982px w-full" key={`${id}-${name}`}>
                    <div className="flex items-center mb-6 rounded-full overflow-hidden">
                      <div className="flex flex-shrink-0">
                        <GatsbyImage className="rounded-full w-16 h-16" image={gatsbyImageData} alt={name} />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-2xl font-bold ml-7">{name}</span>
                        <span className="text-sm text-vividBlue ml-7">{role}</span>
                      </div>
                    </div>
                    <div className="text-sm" dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                )
              }
            )}
          <hr style={{ height: '2px' }} className="my-28 bg-vividBlue opacity-50" />
          <Advisory />
        </div>
      </div>
    </section>
  )
}

export default LeadershipContent

const query = graphql`
  query useLeadership {
    rawData: allLeadershipJson {
      edges {
        node {
          id
          name
          role
          description
          imageSrc {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
