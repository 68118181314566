import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const Advisory = () => {
  const {
    rawData: { edges: advisory }
  } = useStaticQuery(query)

  return (
    <div>
      <h2 className="text-4xl mb-12 font-bold">Advisory board</h2>
      {advisory.length &&
        advisory.map(
          ({
            node: {
              id,
              name,
              role,
              description,
              imageSrc: {
                childImageSharp: { gatsbyImageData }
              }
            }
          }) => {
            return (
              <div className="flex flex-col mb-12 max-w-982px w-full" key={`${id}-${name}`}>
                <div className="flex items-center mb-6">
                  <div className="flex flex-shrink-0 rounded-full overflow-hidden">
                    <GatsbyImage className="rounded-full w-16 h-16" image={gatsbyImageData} alt={name} />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-2xl font-bold ml-7">{name}</span>
                    <span className="text-sm text-vividBlue ml-7">{role}</span>
                  </div>
                </div>
                <div className="text-sm" dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            )
          }
        )}
    </div>
  )
}

export default Advisory

const query = graphql`
  query useAdvisory {
    rawData: allAdvisoryJson {
      edges {
        node {
          id
          name
          role
          description
          imageSrc {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
